<template>
  <side-content
    modal
    hide-submit
    :title="titleModal"
    @close="closeModal">
    <loading v-if="loading > 0" class="absolute-center" />
    <!-- <template v-else>
      <v-container grid-list-lg>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          style="width: 100%">
          <v-container
            v-if="events.length > 0"
            fluid>
            <v-layout
              row
              wrap>
              <v-flex
                xs12
                sm12>
                <select-events
                  v-model="newMultiplesCases.event"
                  label="Campanhas"
                  :rules="requiredRules"
                  required
                  @change="setEvent" />
              </v-flex>
              <v-flex
                xs6
                sm6>
                <div class="input-group input-group--dirty input-group--required input-group--text-field">
                  <label>Agrupar por CPF?</label>
                  <v-radio-group
                    v-model="newMultiplesCases.groupByCpf"
                    :rules="requiredBoolean"
                    required
                    column>
                    <v-radio
                      width="30"
                      class=""
                      label="Não"
                      :value="false" />
                    <v-radio
                      width="30"
                      class=""
                      label="Sim"
                      :value="true" />
                  </v-radio-group>
                </div>
              </v-flex>
              <v-flex
                xs6
                sm6>
                <div class="input-group input-group--dirty input-group--append-icon input-group--required input-group--text-field primary--text">
                  <label>Agrupar por CNPJ?</label>
                  <v-radio-group
                    v-model="newMultiplesCases.groupByCnpj"
                    :rules="requiredBoolean"
                    required
                    column>
                    <v-radio
                      width="30"
                      class=""
                      label="Não"
                      :value="false" />
                    <v-radio
                      width="30"
                      class=""
                      label="Sim"
                      :value="true" />
                  </v-radio-group>
                </div>
              </v-flex>
              <v-flex
                xs12
                sm12>
                <div class="input-group input-group--dirty input-group--required input-group--text-field">
                  <input-file
                    label="Enviar arquivo"
                    required
                    :rules="requiredRules"
                    @file="file" />
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container v-else>
            <empty-state
              size="75px"
              :icon="$root.icons.link.events"
              class="absolute-center">
              <h1 class="main-title" />
              <h1 class="main-title">
                Crie uma campanha
              </h1>
              <p class="subtitle-1">
                Uma <b>Campanha</b> é criação de regras que serão aplicadas nos casos inseridos e registrados na plataforma <br>Crie uma <b>Campanha</b> em apenas 4 passos
              </p>

              <v-btn
                color="accent"
                class="mt-3"
                depressed
                fab
                @click="createEvent">
                <v-icon>{{ $root.icons.action.add }}</v-icon>
              </v-btn>
            </empty-state>
          </v-container>
        </v-form>
      </v-container>
    </template> -->
    <template v-else>
      <v-container grid-list-lg>
        <v-form ref="form" v-model="valid">
          <v-jsf v-model="model" :schema="schema" :options="formOptions">
            <template v-slot:groupByDocument="slotProps">
              <v-switch v-model="model.groupByDocument" :label="slotProps.label" />
            </template>
            <template v-slot:file="slotProps">
              <v-file-input
                ref="aaa"
                :value="slotProps.value"
                :label="slotProps.label"
                v-on="slotProps.on"
                @change="file" />
            </template>
          </v-jsf>
        </v-form>
      </v-container>
    </template>
    <template v-slot:footer>
      <v-btn color="primary" block @click="createBatch(model)">
        Cadastrar
      </v-btn>
    </template>
  </side-content>
</template>

<script>
  import SidebarContent from '@/components/ui/SidebarContent'
  import SelectEvents from '@/components/ui/SelectEvents'
  import EmptyState from '@/components/ui/EmptyState'
  import Loading from '@/components/ui/Loading'
  import InputFile from '@/components/ui/inputs/InputFile'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import resizing from '@/mixins/resizing'
  import md5 from 'js-md5'
  import { mapGetters } from 'vuex'

  export default {
    components: { SidebarContent, SelectEvents, InputFile, EmptyState, Loading },
    mixins: [ validate, masks, resizing ],
    data: () => ({
      titleModal: 'Importar Lote',
      valid: true,
      openModal: false,
      newMultiplesCases: {
        eventId: '',
        fileName: '',
        fileSize: '',
        groupByCpf: '',
        groupByCnpj: '',
        md5: '',
        countTotal: 0,
        countSuccess: 0,
        countFail: 0,
        state: 'created'
      },
      model: {
        eventId: null,
        groupByDocument: null,
        file: {}
      },
      schema: {
        type: 'object',
        properties: {
          required: [
            'eventId'
          ],
          eventId: {
            type: 'string',
            title: 'Campanha',
            oneOf: [],
            'x-props': {
              placeholder: 'Selecione uma opção',
              loading: true
            }
          },
          groupByDocument: {
            type: 'string',
            title: 'Agrupar por documento'
          },
          file: {
            type: 'object',
            title: 'Arquivo',
            contentMediaType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            writeOnly: true,
            properties: {
              name: {
                type: 'string'
              },
              size: {
                type: 'number'
              },
              type: {
                type: 'string'
              },
              lastModified: {
                type: 'string',
                format: 'date-time'
              }
            }
          }
        }
      },
      formOptions: {
        fieldProps: {
          filled: true
        },
        selectProps: {
          clearable: true
        },
        messages: {
          required: 'Campo obrigatório'
        },
        sectionsClass: 'formSection',
        childrenClass: 'pa-0',
        hideReadOnly: true,
        removeAdditionalProperties: false,
        icons: {
          calendar: ''
        }
      },
    }),
    computed: {
      ...mapGetters({
        events: 'events/list',
        loading: 'events/listLoading',
        currentCompanyId: 'currentCompany/id',
        userId: 'user/id'
      })
    },
    watch: {
      events (value) {
        this.schema.properties.eventId.oneOf = value.map(item => ({ const: item.id, title: item.name }))
        this.schema.properties.eventId['x-props'].loading = false
      }
    },
    mounted () {
      this.$store.dispatch('events/getList', { page: 1, rowsPerPage: -1 })

      this.showModal()
    },
    beforeDestroy () {
      this.openModal = false
    },
    methods: {
      setEvent (event) {
        this.newMultiplesCases.eventId = event.id
      },
      file (data) {
        this.model.file = data
        // this.newMultiplesCases.fileSize = data.file.size
        // this.newMultiplesCases.fileName = data.file.name
        // this.newMultiplesCases.file = data.file
        // this.newMultiplesCases.md5 = data.md5

      },
      createBatch (data) {
        const formData = new FormData()
        formData.append("eventId", data.eventId)
        formData.append("groupByDocument", data.groupByDocument)
        formData.append("fileData", data.file)

        console.log(formData._boundary)

        this.$store.dispatch('cases/import', formData)
      },
      submit () {
        if (this.$refs.form.validate()) {
          const batch = this.newMultiplesCases
          const currentCompany = this.currentCompanyId
          const userAuthenticated = { currentCompany, uid: this.userId }

          const id = this.$uuid.v4()
          this.$store.commit('app/setLoading', false)

          this
            .$store
            .dispatch('cases/import', { ...batch, currentCompany, userAuthenticated, id })
            .then(this._success)
            .catch(this._failure)
        }
      },
      _success () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Importação criada com sucesso'})
        this.$router.push({ name: 'MultiplesCases' })
      },
      _failure () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      },
      showModal () {
        this.openModal = true
      },
      createEvent () {
        this.$nextTick(this.$router.push({name: 'EventNew', query: { returnTo: 'MultiplesCasesNew' }}))
      },
      closeModal () {
        this.$nextTick(this.$router.push({ name: 'MultiplesCases' }))
      }
    }
  }
</script>
