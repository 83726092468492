<template>
  <div>
    <v-text-field
      ref="fileTextField"
      v-model="filename"
      :prepend-icon="$root.icons.layout.attach_file"
      single-line
      :label="label"
      :required="required"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      @click.native="onFocus" />

    <input
      ref="fileInput"
      type="file"
      :accept="accept"
      :disabled="disabled"
      tabindex="0"
      aria-label="Enviar arquivo"
      @change="onFileChange">
  </div>
</template>

<script>
  import md5 from 'js-md5'

  export default{
    props: {
      value: {
        type: [Array, String],
        default: ''
      },
      rules: {
        type: Array,
        default: () => ([])
      },
      accept: {
        type: String,
        default: '*'
      },
      label: {
        type: String,
        default: 'Enviar arquivo'
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      filename: ''
    }),
    watch: {
      value (v) {
        this.filename = v
      }
    },
    mounted () {
      this.filename = this.value
    },

    methods: {
      getFormData (files) {
        const data = new FormData()
        const file = files[0]
        data.append('data', file, file.name) // currently only one file at a time

        return data
      },
      onFocus () {
        !this.disabled && this.$refs.fileInput.click()
      },
      onFileChange ($event) {
        const files = $event.target.files || $event.dataTransfer.files
        const form = this.getFormData(files)
        const reader = new FileReader()

        reader.onload = fileLoadedEvent => {
          if (files) {
            if (files.length > 0) {
              this.filename = [...files].map(file => file.name).join(', ')
            } else {
              this.filename = null
            }
          } else {
            this.filename = $event.target.value.split('\\').pop()
          }
          this.$emit('input', this.filename)
          this.$emit('formData', form)
          this.$emit('file', { file: files[0], md5: md5(fileLoadedEvent.target.result) })
          this.$emit('fileData', { filename: this.filename, content: fileLoadedEvent.target.result, size: files[0].size })
        }
        reader.readAsText(files[0])
      }
    }
  }
</script>

<style scoped>
  input[type=file] {
    position: absolute;
    left: -99999px;
  }
</style>
