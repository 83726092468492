<template>
  <tr tabindex="0">
    <td class="text-uppercase">
      <promise-key :promise="props.item.event" promise-key="name" />
    </td>
    <td>{{ props.item.fileName }}</td>
    <td>{{ props.item.fileSize }}</td>
    <td>{{ props.item.countTotal }}</td>
    <td>{{ props.item.countSuccess }} </td>
    <td>{{ props.item.countFail }}</td>
    <td>{{ $t('batch.state.' + props.item.state) }}</td>
    <td>{{ $d(props.item.insertedAt.toDate(), 'short') }}</td>
  </tr>
</template>

<script>
  import PromiseKey from '@/components/ui/PromiseKey'

  export default {
    components: { PromiseKey },
    props: {
      caseId: {
        type: String,
        default: null
      },
      props: Object
    }
  }
</script>
