<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-card-text class="pa-0 cases-container">
        <app-list
          :total-entries="batches.length"
          :entries="batches"
          :headers="headers"
          :pagination="pagination"
          :is-render="isRender"
          :loading-list="loading"
          @paginate="paginate">
          <template v-slot:row="row">
            <row :props="row.props" />
          </template>

          <template v-slot:empty>
            <empty-state
              size="75px"
              :icon="$root.icons.link.cases"
              class="absolute-center">
              Sem dados até o momento. Nenhuma importação foi executada<br>

              <v-btn
                v-if="$can('MultiplesCases')"
                color="accent"
                depressed
                small
                :to="{ name: 'MultiplesCasesNew' }">
                importar lista de casos
              </v-btn>
            </empty-state>
          </template>
        </app-list>
      </v-card-text>
    </v-card>

    <transition
      name="slide"
      mode="out-in"
      appear>
      <router-view />
    </transition>
  </v-container>
</template>

<script>
  import AppList from '@/components/ui/List'
  import Row from './Row'
  import SidebarContent from '@/components/ui/SidebarContent'
  import resizing from '@/mixins/resizing'
  import EmptyState from '@/components/ui/EmptyState'
  import { mapGetters } from 'vuex'

  export default {
    components: { AppList, Row, EmptyState },
    mixins: [ resizing ],
    data: () => ({
      headers: [
        { text: 'Campanha', value: 'event', sortable: false },
        { text: 'Arquivo', value: 'fileName', sortable: false },
        { text: 'Tamanho', value: 'fileSize', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
        { text: 'Sucesso', value: 'success', sortable: false },
        { text: 'Falha', value: 'fail', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Criado em', value: 'insertedAt', sortable: false }
      ],
      pagination: {
        search: '',
        state: '',
        sortBy: 'insertedAt',
        descending: true
      }
    }),
    computed: {
      ...mapGetters({
        batches: 'casesImport/list',
        loading: 'casesImport/listLoading',
        isRender: 'casesImport/listRender'
      }),
    },
    beforeMount () {
      this.$store.dispatch('casesImport/getList')
    },
    methods: {
      paginate (paginate) {
        this.pagination = paginate
      }
    }
  }
</script>

<style lang="sass">
  .v-input-group__messages.v-input-group__error
    color: inherit!important

  .container--full-height
    height: calc(100vh - 160px)
</style>
